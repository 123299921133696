// 弹幕服务模块 javascript
const danmuService = {
  // 弹幕
  async sendDanmu(content) {
    let videoView = document.getElementById("shipingjian");
    let videoWidth = videoView.offsetWidth;
    let p = document.createElement("p");
    p.style.left = videoWidth + "px";
    p.innerHTML = content;
    videoView.appendChild(p);
    let speedArr = ["normal", "fast", "faster"];
    let speed = speedArr[Math.floor(Math.random() * speedArr.length)];
    Animate(p, speed);
    function Animate(dom, speed) {
      let speedType = {
        normal: 5,
        fast: 10,
        faster: 15,
      };
      let domWidth = dom.offsetWidth;
      let distance = videoWidth;
      console.log("!!!", distance);
      let spend = speed ? speed : "normal";
      let interval = speedType[spend];
      let timer = setInterval(function () {
        distance -= interval;
        dom.style.left = distance + "px";
        if (distance <= -domWidth) {
          clearInterval(timer);
          videoView.removeChild(dom);
        }
      }, 50);
    }
  },
  // 聊天框
  async sendFlt(content) {
    let ulContent = document.getElementById("ui-id");
    let li = document.createElement("li");
    let div = document.createElement("div");
    li.setAttribute("class", "li");
    div.setAttribute("class", "message");
    li.innerHTML = "监播员：" + content;
    ulContent.appendChild(li);
    ulContent.appendChild(div);
    ulContent.parentElement.scrollTo(0, 1000);
  },
};

export default danmuService;
