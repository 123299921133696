import React, { useCallback, useEffect, useState, useRef } from "react";
import "dayjs/locale/zh-cn";
import {
  Button,
  Input,
  Card,
  Table,
  Col,
  Row,
  Select,
  message,
  Watermark,
  Space,
  FloatButton,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { reqLiveList, reqDivideLive } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";
import iconc1 from "@/image/iconc1.png";
import iconc2 from "@/image/iconc2.png";
import iconc3 from "@/image/iconc3.png";
import iconc4 from "@/image/iconc4.png";

const Task = () => {
  let [loading, setLoading] = useState(false);
  const [liveList, setLiveList] = useState([]);
  const [mulist, setMulist] = useState([]);
  const [muid, setMuid] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [liveNum, setLiveNum] = useState();
  const [monitoringLiveNum, setMonitoringLiveNum] = useState();
  const [monitoringUserNum, setMonitoringUserNum] = useState();
  const [planNum, setPlanNum] = useState();
  let token = localStorage.getItem("token");
  const watermarkContent = ["CIBN监播", localStorage.getItem("username")];

  // for search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]?.trim());
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const handleResetAll = () => {
    setSearchText("");
    setFilteredInfo({});
  };
  const handleChange = (_, filters) => {
    setFilteredInfo(filters);
  };
  useEffect(() => {
    for (const key in filteredInfo) {
      if (filteredInfo[key] && filteredInfo[key].length !== 0) {
        setIsSearch(true);
        return;
      }
    }
    setIsSearch(false);
  }, [filteredInfo]);
  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`搜索 ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            过滤
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    filteredValue: filteredInfo[dataIndex] || null,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value?.toLowerCase().trim()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // columns
  const columns = [
    {
      title: "直播间ID",
      dataIndex: "live_id",
      key: "live_id",
      sorter: (a, b) => a.live_id.localeCompare(b.live_id),
      ...getColumnSearchProps("直播间ID", "live_id"),
    },
    {
      title: "直播间名称",
      dataIndex: "live_name",
      key: "live_name",
      sorter: (a, b) => a.live_name.localeCompare(b.live_name),
      ...getColumnSearchProps("直播间名称", "live_name"),
    },
    {
      title: "监播员",
      dataIndex: "muname",
      key: "muname",
      sorter: (a, b) => a.muname.localeCompare(b.muname),
      ...getColumnSearchProps("监播员", "muname"),
    },
    {
      title: "直播开始时间",
      dataIndex: "start_time",
      key: "start_time",
      ...getColumnSearchProps("直播开始时间", "start_time"),
      sorter: (a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      },
      defaultSortOrder: "descend",
    },
    {
      title: "直播结束时间",
      dataIndex: "end_time",
      key: "end_time",
      sorter: (a, b) => {
        return new Date(a.end_time) - new Date(b.end_time);
      },
      ...getColumnSearchProps("直播结束时间", "end_time"),
    },
    {
      title: "主播姓名",
      dataIndex: "live_uname",
      key: "live_uname",
      sorter: (a, b) => a.live_uname.localeCompare(b.live_uname),
      ...getColumnSearchProps("主播姓名", "live_uname"),
    },
    {
      title: "参与嘉宾",
      dataIndex: "guest",
      key: "guest",
      sorter: (a, b) => a.guest.localeCompare(b.guest),
      ...getColumnSearchProps("参与嘉宾", "guest"),
    },
  ];

  // 请求函数
  const getLiveList = useCallback(async () => {
    setLoading(true);
    let res = await reqLiveList("token=" + token);
    if (res) {
      setLiveList(res.data.list);
      setMulist(res.data.mulist);
      setLiveNum(res.data.live_num);
      setMonitoringLiveNum(res.data.monitoring_live_num);
      setMonitoringUserNum(res.data.monitoring_user_num);
      setPlanNum(res.data.plan_num);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getLiveList();
  }, [getLiveList]);

  // divide
  const handleRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowChange,
  };
  const handleMuidChange = (_, r) => {
    if (!r) {
      setMuid("");
      return;
    }
    setMuid(r.key);
  };
  const handleDivideLiveTo = async () => {
    const msg = selectedRowKeys.toString();
    if (msg === "") {
      message.error("未选择直播间");
      return;
    }
    if (!muid) {
      message.error("未选择监播员");
      return;
    }
    const res = await reqDivideLive(
      "live_id=" + msg + "&token=" + token + "&muid=" + muid
    );
    if (res) {
      message.success("发送成功");
      getLiveList();
    }
  };

  return (
    <>
      <Watermark content={watermarkContent}>
        <Card>
          <Row gutter={16} style={{ padding: 8 }}>
            <Col span={6}>
              <Card title="我的监播">
                <div className={styles.card}>
                  <div>
                    <span>{monitoringLiveNum}</span>个直播间
                  </div>
                  <img src={iconc4} alt="" />
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="正在直播">
                <div className={styles.card}>
                  <div>
                    <span>{liveNum}</span>个直播间正在直播
                  </div>
                  <img src={iconc2} alt="" />
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="监播员">
                <div className={styles.card}>
                  <div>
                    <span>{monitoringUserNum}</span>个监播员在线
                  </div>
                  <img src={iconc1} alt="" />
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="待审核直播计划">
                <div className={styles.card}>
                  <div>
                    <span>{planNum}</span>条计划待审核
                  </div>
                  <img src={iconc3} alt="" />
                </div>
              </Card>
            </Col>
          </Row>
          {/* 表格区域 */}
          <Table
            style={{ padding: 8 }}
            loading={loading}
            columns={columns}
            dataSource={liveList}
            bordered={true}
            rowKey="live_id"
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50],
              showTotal: (total) => {
                return `总计 ${total}条`;
              },
            }}
            rowSelection={rowSelection} //当前行选中 check
            onChange={handleChange}
          />
          {/* 底部分配 */}
          <div style={{ marginLeft: 16, marginTop: -48 }}>
            <Button
              style={{ marginRight: "8px" }}
              type="primary"
              onClick={() => {
                setLoading(true);
                handleDivideLiveTo();
                setLoading(false);
              }}
              disabled={loading}
            >
              分配给监播员
            </Button>
            <Select
              placeholder="监播员"
              allowClear
              style={{ width: 120 }}
              onChange={handleMuidChange}
            >
              {mulist?.map((item) => {
                return <Select.Option key={item.muid} value={item.muname} />;
              })}
            </Select>
          </div>
        </Card>
      </Watermark>
      <FloatButton.Group
        shape="circle"
        style={{
          right: 24,
        }}
      >
        <FloatButton
          style={{
            display: isSearch ? "block" : "none",
          }}
          tooltip={<div>重置搜索</div>}
          icon={<ClearOutlined />}
          onClick={handleResetAll}
        />
        <FloatButton.BackTop
          tooltip={<div>回到顶端</div>}
          visibilityHeight={0}
        />
      </FloatButton.Group>
    </>
  );
};
export default Task;
