export function twoTimeInterval(startTime, endTime) {
  let d1 = startTime.replace(/-/g, "/");
  let date1 = new Date(d1);

  let d2 = endTime.replace(/-/g, "/");
  let date2 = new Date(d2);

  let dateDiff = date2.getTime() - date1.getTime();

  let days = Math.floor(dateDiff / (24 * 3600 * 1000));

  let residue1 = dateDiff % (24 * 3600 * 1000);
  let hours = Math.floor(residue1 / (3600 * 1000));

  let residue2 = residue1 % (3600 * 1000);
  let minutes = Math.floor(residue2 / (60 * 1000));

  let residue3 = residue2 % (60 * 1000);
  let seconds = Math.round(residue3 / 1000);

  let returnVal =
    (days === 0 ? "" : days + "天") +
    (hours === 0 ? "" : hours + "时") +
    (minutes === 0 ? "" : minutes + "分") +
    (seconds === 0 ? "" : seconds + "秒");

  return returnVal;
}
