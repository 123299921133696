import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Input,
  Button,
  Table,
  Modal,
  Descriptions,
  Space,
  FloatButton,
  Watermark,
  Image,
} from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import XLSX from "xlsx";
import { reqPlanList } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";

const Approval = () => {
  // 声明状态
  const [planList, setPlanList] = useState([]);
  const [displayPlanList, setDisplayPlanList] = useState([]);
  const [planInfo, setPlanInfo] = useState({});
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let token = localStorage.getItem("token");
  const watermarkContent = ["CIBN监播", localStorage.getItem("username")];

  // for table search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchKeys, setSearchKeys] = useState({});
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    let selectedKey = selectedKeys[0];
    if (!selectedKey) selectedKey = "";
    if (selectedKey !== "") {
      selectedKey = selectedKey.trim();
      setSearchKeys(Object.assign(searchKeys, { [dataIndex]: selectedKey }));
    }
    const filterPlanList = planList.filter((item) => {
      for (const key in searchKeys) {
        if (
          !item[key]
            .toString()
            .toLowerCase()
            .includes(searchKeys[key].toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });
    setDisplayPlanList(filterPlanList);
    setTotal(filterPlanList.length);
    setSearchText(selectedKey);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, dataIndex) => {
    delete searchKeys[dataIndex];
    setSearchKeys(searchKeys);
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`搜索 ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            查询
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            过滤
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleResetAll = () => {
    setSearchKeys({});
    setSearchText("");
    setDisplayPlanList(planList);
    setTotal(planList.length);
  };

  // 结构
  const columns = [
    {
      title: "直播间名称",
      dataIndex: "live_name",
      width: "9%",
      sorter: (a, b) => a.live_name.localeCompare(b.live_name),
      filteredValue: searchKeys.live_name || null,
      ...getColumnSearchProps("直播间名称", "live_name"),
    },
    {
      title: "主播姓名",
      dataIndex: "live_uname",
      width: "9%",
      sorter: (a, b) => a.live_uname.localeCompare(b.live_uname),
      filteredValue: searchKeys.live_uname || null,
      ...getColumnSearchProps("主播姓名", "live_uname"),
    },
    {
      title: "认证编号",
      dataIndex: "certification_no",
      width: "13%",
      sorter: (a, b) => a.certification_no.localeCompare(b.certification_no),
      filteredValue: searchKeys.certification_no || null,
      ...getColumnSearchProps("认证编号", "certification_no"),
    },
    {
      title: "直播开始时间",
      dataIndex: "start_time",
      width: "9%",
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      filteredValue: searchKeys.start_time || null,
      ...getColumnSearchProps("直播开始时间", "start_time"),
    },
    {
      title: "直播结束时间",
      dataIndex: "end_time",
      width: "9%",
      sorter: (a, b) => a.end_time.localeCompare(b.end_time),
      filteredValue: searchKeys.end_time || null,
      ...getColumnSearchProps("直播结束时间", "end_time"),
    },
    {
      title: "参与嘉宾",
      dataIndex: "guest",
      sorter: (a, b) => a.guest.localeCompare(b.guest),
      filteredValue: searchKeys.guest || null,
      ...getColumnSearchProps("参与嘉宾", "guest"),
    },
    {
      title: "审核状态",
      dataIndex: "process_status",
      width: "9%",
      sorter: (a, b) => a.process_status.localeCompare(b.process_status),
      filteredValue: searchKeys.process_status || null,
      ...getColumnSearchProps("审核状态", "process_status"),
    },
    {
      title: "审核时间",
      dataIndex: "process_time",
      width: "9%",
      sorter: (a, b) => a.process_time.localeCompare(b.process_time),
      filteredValue: searchKeys.process_time || null,
      ...getColumnSearchProps("审核时间", "process_time"),
      defaultSortOrder: "descend",
    },
    {
      title: "审核人",
      dataIndex: "process_uname",
      width: "9%",
      sorter: (a, b) => a.process_uname.localeCompare(b.process_uname),
      filteredValue: searchKeys.process_uname || null,
      ...getColumnSearchProps("审核人", "process_uname"),
    },
    {
      title: "相关操作",
      dataIndex: "plan_id",
      width: "9%",
      fixed: "right",
      render: (e, r) => (
        <div className={styles.box}>
          <Button
            onClick={() => showDetail(r)}
            style={{ background: "#7BA8FF" }}
          >
            查看详情
          </Button>
        </div>
      ),
    },
  ];

  // 查看详情
  const showDetail = (record) => {
    setPlanInfo(record);
    setIsModalOpenDetail(true);
  };
  const hideDetail = () => {
    setIsModalOpenDetail(false);
  };

  // 排序
  const [sortedInfo, setSortedInfo] = useState({});
  const sortList = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  // 声明列表接口函数
  const getPlanList = useCallback(async () => {
    setLoading(true);
    let res = await reqPlanList("token=" + token);
    if (res) {
      setPlanList(res.data.list);
      setDisplayPlanList(res.data.list);
      setTotal(res.data.list_num);
      setLoading(false);
    }
  }, [token]);

  const handleExportAll = () => {
    const datalist = [
      columns.map((column) => column.title),
      ...displayPlanList.map((record) =>
        columns.map((col) => record[col.dataIndex])
      ),
    ];
    const ws = XLSX.utils.aoa_to_sheet(datalist);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "table.xlsx");
  };

  useEffect(() => {
    getPlanList();
  }, [getPlanList]);

  return (
    <>
      <Watermark content={watermarkContent}>
        <Card>
          <Table
            columns={columns}
            loading={loading}
            scroll={{
              x: 1320,
            }}
            dataSource={displayPlanList}
            rowKey="plan_id"
            onChange={sortList}
            sortedInfo={sortedInfo}
            bordered={true}
            pagination={{
              total,
              current,
              pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: [20, 50, 100],
              showTotal: (total) => {
                return `总计 ${total}条`;
              },
              onChange: (page, pageSize) => {
                setPageSize(pageSize);
                setCurrent(page);
              },
            }}
            onRow={(record) => {
              return {
                onMouseEnter: () => {},
              };
            }}
          />
        </Card>
      </Watermark>
      <Modal
        title="直播计划表"
        open={isModalOpenDetail}
        onOk={hideDetail}
        onCancel={hideDetail}
        okText="确认"
        cancelText="取消"
        width={800}
      >
        <Watermark content={watermarkContent}>
          <Descriptions
            column={2}
            labelStyle={{ width: "22%", textAlign: "center" }}
            contentStyle={{ width: "28%" }}
            size="small"
            title=""
            bordered
          >
            <Descriptions.Item label="申请方">
              {planInfo.applicant_org}
            </Descriptions.Item>
            <Descriptions.Item label="申请人">
              {planInfo.applicant}
            </Descriptions.Item>
            <Descriptions.Item label="直播开始时间">
              {planInfo.start_time}
            </Descriptions.Item>
            <Descriptions.Item label="直播结束时间">
              {planInfo.end_time}
            </Descriptions.Item>
            <Descriptions.Item label="直播间ID">
              {planInfo.live_id}
            </Descriptions.Item>
            <Descriptions.Item label="直播间名称">
              {planInfo.live_name}
            </Descriptions.Item>
            <Descriptions.Item label="主播姓名">
              {planInfo.live_uname}
            </Descriptions.Item>
            <Descriptions.Item label="认证编号">
              {planInfo.certification_no}
            </Descriptions.Item>
            <Descriptions.Item label="参与嘉宾">
              {planInfo.guest}
            </Descriptions.Item>
            <Descriptions.Item label="播出平台">
              {planInfo.platform}
            </Descriptions.Item>
            <Descriptions.Item label="送审状态">
              {planInfo.goprocess_status}
            </Descriptions.Item>
            <Descriptions.Item label="审核状态">
              {planInfo.process_status}
            </Descriptions.Item>
            <Descriptions.Item label="活动类型">
              {planInfo.event_type}
            </Descriptions.Item>
            <Descriptions.Item label="活动主办方">
              {planInfo.event_organizer}
            </Descriptions.Item>
            <Descriptions.Item label="活动合作方">
              {planInfo.event_partner}
            </Descriptions.Item>
            <Descriptions.Item label="来源渠道方">
              {planInfo.channel_name}
            </Descriptions.Item>
            <Descriptions.Item label="自制或转播">
              {planInfo.source}
            </Descriptions.Item>
            <Descriptions.Item label="转播来源">
              {planInfo.relay_source}
            </Descriptions.Item>
            <Descriptions.Item label="编码格式">
              {planInfo.encoding_format}
            </Descriptions.Item>
            <Descriptions.Item label="横/竖屏">
              {planInfo.screen_format}
            </Descriptions.Item>
            <Descriptions.Item label="直播活动分类">
              {planInfo.live_event_type}
            </Descriptions.Item>
            <Descriptions.Item label="直播监播分类">
              {planInfo.live_monitoring_type}
            </Descriptions.Item>
            <Descriptions.Item label="运营负责人">
              {planInfo.operate_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.operate_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="监播负责人">
              {planInfo.monitoring_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.monitoring_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="项目负责人">
              {planInfo.project_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.project_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="业务负责人">
              {planInfo.business_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.business_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="技术负责人">
              {planInfo.technology_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.technology_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="来源负责人">
              {planInfo.channel_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.channel_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="转播地址" span={2}>
              {planInfo.source_addr}
            </Descriptions.Item>
            <Descriptions.Item label="实时直播" span={2}>
              {planInfo.play_addr}
            </Descriptions.Item>
            <Descriptions.Item label="备片列表" span={2}>
              {planInfo.preplay_addr}
            </Descriptions.Item>
            <Descriptions.Item label="主播认证照" span={2}>
              {planInfo.certif_imgs &&
                Array.isArray(planInfo.certif_imgs) &&
                planInfo.certif_imgs.map((item, index) => {
                  return <Image key={index} height={200} src={item} />;
                })}
            </Descriptions.Item>
          </Descriptions>
        </Watermark>
      </Modal>
      <FloatButton.Group
        shape="circle"
        style={{
          right: 24,
        }}
      >
        <FloatButton
          badge={{
            count: displayPlanList.length,
            overflowCount: 999,
          }}
          tooltip={
            <div>
              导出{Object.keys(searchKeys).length === 0 ? "全部" : "查询"}数据
            </div>
          }
          icon={<DownloadOutlined />}
          onClick={handleExportAll}
        />
        <FloatButton
          tooltip={<div>重置搜索</div>}
          icon={<ClearOutlined />}
          onClick={handleResetAll}
        />
        <FloatButton.BackTop
          tooltip={<div>回到顶端</div>}
          visibilityHeight={0}
        />
      </FloatButton.Group>
    </>
  );
};
export default Approval;
