import request from "@/util/http/request";
export const reqPageList = (data) => {
  return request.post(`/monitoring/scheduList`, data);
};
export const reqUpScheduling = (data) => {
  return request.post(`/monitoring/upScheduling`, data);
};
export const reqGoMonitoring = (data) => {
  return request.post(`/monitoring/goMonitoring`, data);
};
export const reqGoLive = (data) => {
  return request.post(`/monitoring/goLive`, data);
};

// login
export const reqGetCaptcha = () => {
  return request.get(`/home/loginData`);
};
export const reqLogin = (data) => {
  return request.post(`/home/login`, data);
};

export const reqDropStream = (data) => {
  return request.post(`monitoring/dropStream`, data);
};
export const reqPushTolive = (data) => {
  return request.post(`monitoring/pushTolive`, data);
};
// 审核列表
export const reqPlanList = (data) => {
  return request.post(`/plan/planList`, data);
};
// 直播计划详情
export const reqPlanInfo = (data) => {
  return request.post(`/plan/planInfo`, data)
}
// 设置审核是否通过
export const reqPlanStatus = (data) => {
  return request.post(`/plan/planStatus`, data)
}
// 审核管理
export const reqLiveList = (data) => {
  return request.post(`/plan/liveList`, data)
}
// 操作日志接口
export const reqLogList = (data) => {
  return request.post(`/plan/logList`, data)
}
// 断流反馈
export const reqDropStreamDesc = (data) => {
  return request.post(`/plan/dropStreamDesc`, data)
}
// 关闭监播房间
export const reqCloseRoom = (data) => {
  return request.post(`/monitoring/closeRoom`, data)
}
// 分配监播任务
export const reqDivideLive = (data) => {
  return request.post(`/monitoring/divideLive`, data)
}

// 短视频
export const reqVideoList = (params) => {
  return request.get(`/shortVideo/videoList?` + gen(params));
};
export const reqVideoStatus = (data) => {
  return request.post(`/shortVideo/videoProcess`, gen(data))
}

function gen(params) {
  let arr = [];
  Object.entries(params).forEach(([key, val]) => {
    arr.push(key + "=" + val);
  });
  return arr.join("&")
}