import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PubSub } from "pubsub-js";
import { setPlayer, unloadPlayer } from "@/components/player";
import { reqGoMonitoring } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";
import { message } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";

const Monitoring = () => {
  const [playUrl, setPlayUrl] = useState();
  const [listArr, setListArr] = useState([]);
  const dataScreenRef = useRef(null);
  const playerMainRef = useRef(null);
  const playerHoverRef = useRef(null);
  const navigate = useNavigate();

  /* 浏览器监听 resize 事件 */
  const getScale = (width = 1920, height = 1080) => {
    let ww = window.innerWidth / width;
    //
    let wh = (window.innerHeight - 46) / height;
    return ww < wh ? ww : wh;
  };
  if (dataScreenRef.current) {
    //
    dataScreenRef.current.style.transform = `scale(${getScale()})`;
    dataScreenRef.current.style.width = `1920px`;
    dataScreenRef.current.style.height = `1080px`;
  }
  const resize = () => {
    if (dataScreenRef.current) {
      dataScreenRef.current.style.transform = `scale(${getScale()})`;
    }
  };
  window.addEventListener("resize", resize);

  let obj = {
    1: { width: "100%", height: "100%" },
    2: { width: "50%", height: "50%" },
    3: { width: "50%", height: "50%" },
    4: { width: "50%", height: "50%" },
    5: { width: "33.33%", height: "33.33%" },
    6: { width: "33.33%", height: "33.33%" },
    7: { width: "33.33%", height: "33.33%" },
    8: { width: "33.33%", height: "33.33%" },
    9: { width: "33.33%", height: "33.33%" },
    10: { width: "25%", height: "25%" },
    11: { width: "25%", height: "25%" },
    12: { width: "25%", height: "25%" },
    13: { width: "25%", height: "25%" },
    14: { width: "25%", height: "25%" },
    15: { width: "25%", height: "25%" },
  };

  const getMsg = useCallback(
    async (topic, msg) => {
      if (msg === "close") {
        message.error("WebSocket退出");
        window.location.assign("/login");
      } else {
        let res = JSON.parse(msg);
        if (res.list) {
          console.log("WS receive message: ", res);
          setPlayUrl(res.url);
          setListArr(res.list);
        }
        if (res.video_title) {
          console.log("WS receive change video title message: ", res);
          setListArr(
            listArr.map((item) => {
              if (item.live_id === res.live_id.toString()) {
                item.video_title = res.video_title;
              }
              return item;
            })
          );
        }
      }
    },
    [listArr]
  );

  const getGoMonitoring = useCallback(async () => {
    let token = localStorage.getItem("token");
    let schedulingId = localStorage.getItem("scheduling_id");
    let res = await reqGoMonitoring(
      "scheduling_id=" + schedulingId + "&token=" + token
    );
    if (res) {
      setPlayUrl(res.data.url);
      setListArr(res.data.list);
      if (res.data.url === undefined) {
        message.error("没有未监播状态的视频流");
        navigate("/task");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (!playUrl) {
      getGoMonitoring();
    }
    if (playUrl) {
      playerMainRef.current = setPlayer(playUrl, "videoElementMain", false);
    }
    let messageSocket = PubSub.subscribe("message", getMsg);

    //在组件卸载的时候，关闭订阅
    return () => {
      PubSub.unsubscribe(messageSocket);
      unloadPlayer(playerHoverRef.current);
      playerHoverRef.current = null;
      unloadPlayer(playerMainRef.current);
      playerMainRef.current = null;
    };
  }, [getGoMonitoring, getMsg, playUrl]);

  return (
    <>
      <div className={styles.dataScreen} ref={dataScreenRef}>
        <div className={styles.dataScreen_layer1}>
          <video id="videoElementMain" />
        </div>
        <div className={styles.dataScreen_layer2}>
          {listArr?.map((item) => {
            return (
              <div
                className={styles.floatingPlayer}
                style={obj[listArr.length] ? obj[listArr.length] : obj["16"]}
                key={item.live_id}
              >
                <video id={`videoElementHover-` + item.live_id} />
              </div>
            );
          })}
        </div>
        <div className={styles.dataScreen_layer3}>
          {listArr?.map((item) => {
            return (
              <div
                className={styles.floatingLayer}
                style={obj[listArr.length] ? obj[listArr.length] : obj["16"]}
                key={item.live_id}
              >
                <div className={styles.floating2}>
                  <div className={styles.floating2_div}>
                    <div className={styles.floating2_div1}>
                      <img src={item.cover_img} alt="" />
                    </div>
                    <div className={styles.floating2_div2}>
                      <span className={styles.span1}>{item.title}</span>
                      <span className={styles.span2}>{item.real_name}</span>
                    </div>
                  </div>
                  {item.if_collection === 1 && (
                    <div className={styles.floating2Collection}>
                      <VideoCameraOutlined style={{ marginLeft: 5 }} />
                      <span className={styles.collectionVideoTitle}>
                        {item.video_title}
                      </span>
                    </div>
                  )}
                  <input
                    onClick={() => {
                      navigate(`/liveroom/${item.live_id}`);
                    }}
                    type="button"
                    name="button"
                    value={"进入直播间"}
                    className="pointer"
                  />
                  <input
                    onClick={() => {
                      let currentVideoElementHoverID;
                      if (playerHoverRef.current) {
                        currentVideoElementHoverID =
                          playerHoverRef.current._mediaElement.id;
                        unloadPlayer(playerHoverRef.current);
                        document.getElementById(
                          currentVideoElementHoverID.replace(
                            "videoElementHover-",
                            "buttonHover-"
                          )
                        ).value = "播放声音";
                        playerHoverRef.current = null;
                        if (listArr.length === 1) {
                          playerMainRef.current = setPlayer(
                            playUrl,
                            "videoElementMain",
                            false
                          );
                        }
                      }
                      if (
                        currentVideoElementHoverID !==
                        "videoElementHover-" + item.live_id
                      ) {
                        playerHoverRef.current = setPlayer(
                          item.play_addr,
                          "videoElementHover-" + item.live_id,
                          true
                        );
                        document.getElementById(
                          "buttonHover-" + item.live_id
                        ).value = "静音";
                        if (listArr.length === 1) {
                          unloadPlayer(playerMainRef.current);
                          playerMainRef.current = null;
                        }
                      }
                    }}
                    id={"buttonHover-" + item.live_id}
                    type="button"
                    name="button"
                    value="播放声音"
                    className="pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Monitoring;
