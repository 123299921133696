import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactPlayer from "react-player";
import locale from "antd/es/date-picker/locale/zh_CN";
import {
  Input,
  Button,
  Card,
  Table,
  Modal,
  message,
  Watermark,
  Popconfirm,
  Tooltip,
  Form,
  Popover,
  Space,
  Typography,
} from "antd";
import {
  PlayCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { reqVideoList, reqVideoStatus } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";
const { Paragraph } = Typography;

const ShortVideo = () => {
  let [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [total, setTotal] = useState(1);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [goodsName, setGoodsName] = useState("");
  const [title, setTitle] = useState("");
  const [statusName, setStatusName] = useState("");

  const [currentItem, setCurrentItem] = useState("");
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const [openDenyModal, setOpenDenyModal] = useState(false);
  const [denyId, setDenyId] = useState();
  const denyFormRef = useRef(null);

  let token = localStorage.getItem("token");
  const watermarkContent = ["CIBN监播", localStorage.getItem("username")];

  // for search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    switch (dataIndex) {
      case "goods_name":
        setGoodsName(selectedKeys[0] || "");
        break;
      case "title":
        setTitle(selectedKeys[0] || "");
        break;
      case "status_name":
        setStatusName(selectedKeys[0] || "");
        break;
      default:
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`查询`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            查询
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            过滤
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "直播间",
      dataIndex: "live_name",
    },
    {
      title: "视频id",
      dataIndex: "video_id",
    },
    {
      title: "视频标题",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "相关商品",
      dataIndex: "goods_name",
      ...getColumnSearchProps("goods_name"),
    },
    {
      title: "视频链接",
      dataIndex: "video_url",
      width: "120px",
      render: (e) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "more",
            }}
            copyable={{
              text: e,
            }}
          >
            {e}
          </Paragraph>
        );
      },
    },
    {
      title: "时长",
      dataIndex: "video_duration",
    },
    {
      title: "上传时间",
      dataIndex: "add_time",
      locale,
    },
    {
      title: "审核状态",
      dataIndex: "status",
      ...getColumnSearchProps("status_name"),
      render: (e, record) => {
        return e === 0 ? (
          <span style={{ color: "black" }}>{record.status_name}</span>
        ) : (
          <Popover
            content={
              <div>
                <p>审核人：{record.process_uname || "未知"}</p>
                <p>审核时间：{record.process_time}</p>
                {e === "2" && <p>拒绝理由：{record.process_desc}</p>}
              </div>
            }
            title="详情"
            trigger="hover"
          >
            <span
              style={{
                color: e === "1" ? "green" : "red",
              }}
            >
              {record.status_name}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "审核",
      dataIndex: "video_url",
      width: "70px",
      render: (_, record) => (
        <div>
          <Button
            type="link"
            icon={<PlayCircleOutlined />}
            onClick={() => {
              setCurrentItem(record);
              setOpenVideoModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const updateVideoStatus = async (video_id, status, desc) => {
    setOpenVideoModal(false)
    let params = {
      token: token,
      video_id: video_id,
      status: status,
      process_desc: desc
    }
    let res = await reqVideoStatus(params);
    if (res) {
      message.success("成功");
      const list = videoList.map((item) => {
        if (item.video_id === video_id) {
          item.status_name = status === 1 ? "审核已通过" : "审核未通过";
        }
        return item;
      });
      setVideoList(list);
    }
  };
  // check form valid
  const validateReason = (video_id) => {
    denyFormRef.current
      .validateFields()
      .then((values) => {
        updateVideoStatus(video_id, 2, values.reason);
        setOpenDenyModal(false);
      })
      .catch((info) => {
        let error = info.errorFields;
        error.forEach((item) => {
        });
      });
  };

  const getVideoList = useCallback(async () => {
    setLoading(true);
    let params = {
      token: token,
      page_size: pageSize,
      page: current,
      goods_name: goodsName,
      title: title,
      status_name: statusName
    }
    let res = await reqVideoList(params);
    if (res) {
      setVideoList(res.data.video_list);
      setTotal(res.data.video_num);
      setLoading(false);
    }
  }, [token, pageSize, current, goodsName, title, statusName]);

  useEffect(() => {
    getVideoList();
  }, [getVideoList]);

  return (
    <>
      <Watermark content={watermarkContent}>
        <Card>
          <Table
            columns={columns}
            loading={loading}
            dataSource={videoList}
            rowKey="video_id"
            bordered={true}
            pagination={{
              total,
              current,
              pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: [20, 50, 100],
              showTotal: (total) => {
                return `总计 ${total}条`;
              },
              onChange: (page, pageSize) => {
                setPageSize(pageSize);
                setCurrent(page);
              },
            }}
          />
        </Card>
      </Watermark>

      <Modal
        title="短视频审核"
        centered
        open={openVideoModal}
        onOk={() => setOpenVideoModal(false)}
        onCancel={() => setOpenVideoModal(false)}
        width={1000}
        destroyOnClose
        footer={[]}
        zIndex={998}
      >
        <Watermark content={watermarkContent}>
          <ReactPlayer
            playing={true}
            controls={true}
            width={960}
            height={540}
            url={currentItem.video_url}
          />
          {
            currentItem.status === 0 && (
            <div className={styles.videoBtn} >
              <Tooltip placement="bottom" title="通过">
                <Button
                  style={{ background: "#42B71F", marginRight: 20 }}
                  type="primary"
                  onClick={() => updateVideoStatus(currentItem.video_id, 1, "")}
                >
                  通过
                </Button>
              </Tooltip>
              <Popconfirm
                placement="topLeft"
                title={"确认拒绝？"}
                onConfirm={() => {
                  setOpenDenyModal(true);
                  setDenyId(currentItem.video_id);
                }}
              >
                <Tooltip placement="bottom" title="拒绝">
                  <Button style={{ marginLeft: "5px" }} danger type="primary">
                    拒绝
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
            )
          }
        </Watermark>
      </Modal>

      <Modal
        title="请填写未通过原因"
        open={openDenyModal}
        onOk={() => {
          validateReason(denyId);
        }}
        onCancel={() => setOpenDenyModal(false)}
        destroyOnClose
      >
        <Form ref={denyFormRef}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "未通过原因必须输入",
              },
            ]}
          >
            <Input.TextArea
              allowClear
              placeholder="请输入未通过原因"
              style={{
                height: 120,
                resize: "none",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ShortVideo;
