import axios from "axios";
import { message } from "antd";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const request = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 10000,
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    NProgress.start();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (response.data.msg === "params:token error") {
      message.error("登录已过期");
      console.log("登录已过期");
      localStorage.removeItem("token");
      localStorage.removeItem("scheduling_id");
      localStorage.removeItem("username");
      window.location.assign("/login");
      return null;
    }
    return response.data;
  },
  async (error) => {
    NProgress.done();
    try {
      return await Promise.reject(error.message);
    } catch (msg) {
      message.error(msg);
    }
  }
);
export default request;
