import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  Button,
  Row,
  Col,
  message,
  Popconfirm,
  Avatar,
  Tooltip,
} from "antd";
import { createWebSocket, closeWebSocket } from "@/components/ws";
import styles from "./index.module.css";
import logo from "@/image/logo.svg";
import { reqCloseRoom } from "@/api/pagelist/pagelist";

const Header = () => {
  let display = "none";
  let token = localStorage.getItem("token");
  let username = localStorage.getItem("username");
  username = !username || username.length === 0 ? "未知用户" : username;
  let pathname = window.location.pathname;
  if (token) display = "block";
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();

  // 导航栏
  const items = [
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("monitoring")}
          target="_blank"
          rel="noopener noreferrer"
        >
          监播
        </Button>
      ),
      key: "monitoring",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("task")}
          target="_blank"
          rel="noopener noreferrer"
        >
          监播任务管理
        </Button>
      ),
      key: "task",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("approval")}
          target="_blank"
          rel="noopener noreferrer"
        >
          审核管理
        </Button>
      ),
      key: "approval",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("approvalhistory")}
          target="_blank"
          rel="noopener noreferrer"
        >
          审核记录
        </Button>
      ),
      key: "approvalhistory",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("history")}
          target="_blank"
          rel="noopener noreferrer"
        >
          监播记录
        </Button>
      ),
      key: "history",
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => handleClick("short-video")}
          target="_blank"
          rel="noopener noreferrer"
        >
          短视频审核
        </Button>
      ),
      key: "short-video",
    },
  ];

  const handleClick = (key) => {
    setCurrent(key);
    navigate(key);
  };

  useEffect(() => {
    setCurrent(
      pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length)
    );
  }, [pathname]);

  const handleConfirm = () => {
    let schedulingId = localStorage.getItem("scheduling_id");
    let res = reqCloseRoom("scheduling_id=" + schedulingId + "&token=" + token);
    if (res) {
      message.success("成功退出");
      localStorage.removeItem("token");
      localStorage.removeItem("scheduling_id");
      localStorage.removeItem("username");
      navigate("/login");
    }
  };

  useEffect(() => {
    let websocket;
    let schedulingId = localStorage.getItem("scheduling_id");
    let url = "wss://jianbo.cbnlive.cn/wsapi/?scheduling_id=" + schedulingId;
    if (token) websocket = createWebSocket(url);

    return () => {
      websocket && closeWebSocket(websocket);
    };
  }, [token]);

  return (
    <div style={{ display: display }}>
      <Row justify="space-between">
        <Col className={styles.logobox} span={4}>
          <img
            className={styles.logoimg}
            src={logo}
            onClick={() => handleClick("task")}
            alt=""
          />
        </Col>
        <Col style={{ background: "blue" }} span={14}>
          <Menu mode="horizontal" items={items} selectedKeys={current} />
        </Col>
        <Col className={styles.logoutbox} span={6}>
          <Button onClick={() => window.location.reload()}>刷新页面</Button>
          <span style={{ width: 120, textAlign: "center" }}>
            <Tooltip placement="bottom" title={username}>
              <Avatar
                style={{
                  backgroundColor: "#7265e6",
                  verticalAlign: "middle",
                }}
              >
                {username.length > 3 || username.length === 1
                  ? username[0]
                  : username.length === 2
                  ? username
                  : username.substring(1, 3)}
              </Avatar>
            </Tooltip>
          </span>
          <Popconfirm
            placement="bottomRight"
            title={"确认退出监播系统？"}
            onConfirm={handleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginRight: 40 }} type="primary">
              退出登录
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </div>
  );
};
export default Header;
