import { React, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Button, Modal } from "antd";
import { reqDropStream, reqDropStreamDesc } from "@/api/pagelist/pagelist";

const { TextArea } = Input;

const TaobaoControl = () => {
  let { id } = useParams();
  const [isModalOpenCut1, setIsModalOpenCut1] = useState(false); // 断流确认框
  const [isModalOpenCut2, setIsModalOpenCut2] = useState(false); // 断流原因输入框
  let token = localStorage.getItem("token");

  // 断流确认框
  const showModalCut1 = () => {
    setIsModalOpenCut1(true);
  };
  const handleOkCut1 = () => {
    setIsModalOpenCut1(false);
    callDropStream();
    showModalCut2();
  };
  const handleCancelCut1 = () => {
    setIsModalOpenCut1(false);
  };

  // 断流原因输入框
  const showModalCut2 = () => {
    setIsModalOpenCut2(true);
  };
  const handleOkCut2 = () => {
    setIsModalOpenCut2(false);
    callSendReason(document.getElementById("textReason").value);
  };
  const handleCancelCut2 = () => {
    setIsModalOpenCut2(false);
  };

  // 断流接口
  const callDropStream = async () => {
    await reqDropStream("live_id=" + id + "&token=" + token);
  };

  // 提交断流原因接口
  const callSendReason = async (desc) => {
    // TODO
    await reqDropStreamDesc("live_id=" + id + "&token=" + token + "&desc=" + desc);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        danger
        onClick={showModalCut1}
      >
        推荐位下线
      </Button>
      <Modal title="确认推荐位下线" open={isModalOpenCut1} onOk={handleOkCut1} onCancel={handleCancelCut1} />
      <Modal title="填写推荐位下线原因" open={isModalOpenCut2} onOk={handleOkCut2} onCancel={handleCancelCut2}>
        <TextArea
          id="textReason"
          placeholder="请输入推荐位下线原因"
          style={{
            height: 120,
            resize: 'none',
          }}
        />
      </Modal>
    </div>
  );
};
export default TaobaoControl;
