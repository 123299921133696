import { Route, Routes, Navigate } from "react-router-dom";
import Login from "@/pages/login";
import Header from "@/components/Header";
import Liveroom from "@/pages/liveroom";
import Monitoring from "@/pages/monitoring";
import Task from "@/pages/task";
import Approval from "@/pages/approval";
import ApprovalHistory from "@/pages/approval/history";
import History from "@/pages/history";
import ShortVideo from "@/pages/short-video"
import { AuthRouter } from "@/components/Router";
import "./App.css";
import "./styles/index.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/login"
          element={
            <AuthRouter login={true}>
              <Login />
            </AuthRouter>
          }
        ></Route>
        <Route
          exact
          path="*"
          element={
            <AuthRouter login={false}>
              <Routes>
                <Route path="/" element={<Task />} />
                <Route path="/liveroom/:id" element={<Liveroom />} />
                <Route path="/monitoring" element={<Monitoring />} />
                <Route path="/task" element={<Task />} />
                <Route path="/approval" element={<Approval />} />
                <Route path="/approvalhistory" element={<ApprovalHistory />} />
                <Route path="/history" element={<History />} />
                <Route path="/short-video" element={<ShortVideo />} />
                <Route exact path="*" element={<Navigate to="/task" />} />
              </Routes>
            </AuthRouter>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
