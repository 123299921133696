import { React, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Space, Button, Dropdown, Modal } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { reqDropStream, reqPushTolive, reqDropStreamDesc } from "@/api/pagelist/pagelist";
import danmuService from "./Theserver";
import styles from "./index.module.css";

const { TextArea } = Input;

const Control = () => {
  let { id } = useParams();
  const [content, setContent] = useState(""); //消息内容
  const [loading, setLoading] = useState(false); //提交按钮加载状态
  const [isModalOpenCut1, setIsModalOpenCut1] = useState(false); // 断流确认框
  const [isModalOpenCut2, setIsModalOpenCut2] = useState(false); // 断流原因输入框
  let token = localStorage.getItem("token");

  // 断流确认框
  const showModalCut1 = () => {
    setIsModalOpenCut1(true);
  };
  const handleOkCut1 = () => {
    setIsModalOpenCut1(false);
    callDropStream();
    showModalCut2();
  };
  const handleCancelCut1 = () => {
    setIsModalOpenCut1(false);
  };

  // 断流原因输入框
  const showModalCut2 = () => {
    setIsModalOpenCut2(true);
  };
  const handleOkCut2 = () => {
    setIsModalOpenCut2(false);
    callSendReason(document.getElementById("textReason").value);
  };
  const handleCancelCut2 = () => {
    setIsModalOpenCut2(false);
  };

  // 断流接口
  const callDropStream = async () => {
    await reqDropStream("live_id=" + id + "&token=" + token);
  };

  // 提交断流原因接口
  const callSendReason = async (desc) => {
    // TODO
    await reqDropStreamDesc("live_id=" + id + "&token=" + token + "&desc=" + desc);
  };

  // 发送内容
  async function handleSendClick() {
    if (!content.trim()) return;
    setLoading(true);
    await danmuService.sendFlt(content);
    await reqPushTolive("live_id=" + id + "&token=" + token + "&content=" + content);
    setLoading(false);
    setContent("");
  }

  // 获取content
  function handleInputChange(e) {
    setContent(e.target.value);
  }

  //填充快捷回复
  const fillContent = (e) => {
    setContent(e.target.textContent);
  };

  //下拉菜单
  const items = [
    { key: "1", label: (<span onClick={fillContent}>散步政治,宗教政策有害信息或宣扬邪恶和封建迷信。</span>), },
    { key: "2", label: (<span onClick={fillContent}>散步淫秽,色情低俗庸俗媚俗或带有性暗示等内容。</span>), },
    { key: "3", label: (<span onClick={fillContent}>散步虚假信息,宣扬暴力、恐怖、传销、诈骗、赌博、贩卖违禁品等。</span>), },
    { key: "4", label: (<span onClick={fillContent}>骚扰、诋毁或恐吓他人等可能引发未成年人模仿的不安全行为和违反社会公德行为。</span>), },
    { key: "5", label: (<span onClick={fillContent}>为存在违法违规或高风险行为的人推广、引流。</span>), },
    { key: "6", label: (<span onClick={fillContent}>夸大产品作用或引用夸张标题,标题与内容不符。</span>), },
    { key: "7", label: (<span onClick={fillContent}>主播肢体行为、语言表达出现不妥或出现低俗词汇。</span>), },
    { key: "8", label: (<span onClick={fillContent}>主播衣着不妥,过于暴露或出现纹身等。</span>), },
    { key: "9", label: (<span onClick={fillContent}>直播画面卡顿,声音过大引起不适或声话不对位等。</span>), },
    { key: "10", label: <span onClick={fillContent}>直播画面出现未报备人员。</span> },
    { key: "11", label: (<span onClick={fillContent}>直播画面出现抽烟、喝酒等不合规行为。</span>), },
    { key: "12", label: (<span onClick={fillContent}>直播过程中出现未报备商品或管制物品等。</span>), },
  ];
  return (
    <div className={styles.controlPanel}>
      <div className={styles.messageSent}>
        <ul id="ui-id"></ul>
      </div>
      <div className={styles.inputBar}>
        <TextArea
          placeholder="请输入消息"
          value={content}
          onChange={handleInputChange}
          style={{
            height: 120,
            resize: 'none',
          }}
        />
        <div className={styles.inputButton}>
          <Dropdown
            menu={{ items }}
            placement="top"
          >
            <Button
              style={{ width: '70%'}}
            >
              <Space>
                快捷回复
                <UpOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Button
            style={{ width: '20%', marginLeft: 4 ,marginRight: 4 }}
            type="primary"
            onClick={handleSendClick}
            loading={loading}
          >
            发送
          </Button>
          <Button
            type="primary"
            danger
            onClick={showModalCut1}
          >
            断流
          </Button>
        </div>
      </div>
      <Modal title="确认断流" open={isModalOpenCut1} onOk={handleOkCut1} onCancel={handleCancelCut1} />
      <Modal title="填写断流原因" open={isModalOpenCut2} onOk={handleOkCut2} onCancel={handleCancelCut2}>
        <TextArea
          id="textReason"
          placeholder="请输入断流原因"
          style={{
            height: 120,
            resize: 'none',
          }}
        />
      </Modal>
    </div>
  );
};
export default Control;
