// import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, message } from "antd";
import Icon, { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProConfigProvider,
  ProFormText,
} from "@ant-design/pro-components";
import { ReactComponent as CaptchaSvg } from "@/image/captcha.svg";
import cibn from "@/image/cibn.svg";
import { reqGetCaptcha, reqLogin } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";

const Login = () => {
  const [captchaImg, setCaptchaImg] = useState();
  const [captchaHash, setCaptchaHash] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const navigate = useNavigate();

  if (typeof WebSocket == "undefined") {
    alert("浏览器不支持WebSocket");
  } else {
    console.log("您的浏览器支持WebSocket");
  }

  // get captcha
  const getCaptcha = async () => {
    let res = await reqGetCaptcha();
    if (res) {
      setCaptchaImg(res.data.code_img);
      setCaptchaHash(res.data.hash);
    }
    setFirstTime(false);
  };

  // login
  const handleLogin = async (values: API.LoginParams) => {
    try {
      const res = await reqLogin(
        "username=" +
          values.username +
          "&password=" +
          values.password +
          "&hash=" +
          captchaHash +
          "&code=" +
          values.captcha
      );
      if (res.code === 1) {
        message.success("登录成功");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("scheduling_id", res.data.scheduling_id);
        localStorage.setItem("username", res.data.muname);
        navigate("/monitoring");
      } else {
        message.error(res.msg);
        getCaptcha();
      }
    } catch (error) {
      message.error("登录失败", error);
    }
  };

  return (
    <div className={styles.container}>
      <ProConfigProvider hashed={false}>
        <div className={styles.content}>
          <LoginForm
            logo={cibn}
            title="CIBN监播"
            subTitle="欢迎登录监播平台"
            onFinish={async (values) => {
              await handleLogin(values as API.LoginParams);
            }}
          >
            <div className={styles.form}>
              <ProFormText
                name="username"
                fieldProps={{
                  size: "large",
                  prefix: <UserOutlined className={"prefixIcon"} />,
                }}
                placeholder={"请输入用户名"}
                rules={[
                  {
                    required: true,
                    message: "请输入用户名!",
                  },
                ]}
              />
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined className={"prefixIcon"} />,
                }}
                placeholder={"请输入密码"}
                rules={[
                  {
                    required: true,
                    message: "请输入密码！",
                  },
                ]}
              />
              <Row>
                <Col span={15} onFocus={() => firstTime && getCaptcha()}>
                  <ProFormText
                    name="captcha"
                    fieldProps={{
                      size: "large",
                      prefix: <Icon component={CaptchaSvg} />,
                    }}
                    placeholder={"请输入图片验证码"}
                    rules={[
                      {
                        required: true,
                        message: "请输入验证码！",
                      },
                      {
                        pattern: /^\w{4}$/,
                        message: "验证码格式错误！",
                      },
                    ]}
                  />
                </Col>
                <Col offset={1} span={8}>
                  <img
                    style={{ width: "100%", height: 40, cursor: "pointer" }}
                    src={captchaImg}
                    alt=""
                    onClick={getCaptcha}
                  />
                </Col>
              </Row>
            </div>
          </LoginForm>
        </div>
      </ProConfigProvider>
    </div>
  );
};

export default Login;
