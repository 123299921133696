import { useEffect, useState, React, useCallback } from "react";
import { useParams } from "react-router-dom";
import { PubSub } from "pubsub-js";
import { setPlayer, unloadPlayer } from "@/components/player";
import { reqGoLive } from "@/api/pagelist/pagelist";
import Info from "./info";
import Control from "./control";
import TaobaoControl from "./controltaobao";
import { message } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import styles from "./index.module.css";

const Liveroom = () => {
  const [liveList, setLiveList] = useState([]);
  const [showControl, setShowControl] = useState("none");
  const [showTaobaoControl, setShowTaobaoControl] = useState("none");
  let { id } = useParams();

  const reqGoLiveList = useCallback(async () => {
    let token = localStorage.getItem("token");
    let res = await reqGoLive("live_id=" + id + "&token=" + token);
    if (res) {
      setLiveList(res.data);
      if (res.data.play_addr.includes("taobao")) {
        setShowTaobaoControl("block");
      } else {
        setShowControl("block");
      }
    }
  }, [id]);

  const getMsg = (_, msg) => {
    if (msg === "close") {
      message.error("WebSocket退出");
      window.location.assign("/login");
    } else {
      let res = JSON.parse(msg);
      if (res.video_title) {
        if (liveList.live_id === res.live_id.toString()) {
          console.log("WS receive change video title message: ", res);
          setLiveList({ ...liveList, video_title: res.video_title });
        }
      }
    }
  };

  useEffect(() => {
    let player;

    if (!liveList.play_addr) {
      reqGoLiveList();
    }
    if (liveList.play_addr) {
      player = setPlayer(liveList.play_addr, "videoElement", true);
    }
    let messageSocket = PubSub.subscribe("message", getMsg);

    return () => {
      PubSub.unsubscribe(messageSocket);
      unloadPlayer(player);
    };
  });

  return (
    <>
      <div className={styles.dataScreen_main}>
        <div className={styles.videoPanel}>
          <video id="videoElement" />
          {liveList.if_collection === 1 && (
            <div className={styles.floating2Collection}>
              <VideoCameraOutlined style={{ marginLeft: 5 }} />
              <span className={styles.collectionVideoTitle}>
                {liveList.video_title}
              </span>
            </div>
          )}
          <div style={{ display: showControl }}>
            <Control />
          </div>
          <div style={{ display: showTaobaoControl }}>
            <TaobaoControl />
          </div>
        </div>
        <Info items={liveList.plan_info} />
      </div>
    </>
  );
};
export default Liveroom;
