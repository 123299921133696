import { Navigate } from "react-router-dom";

const getToken = () => {
  return localStorage.getItem("token");
};

function AuthRouter(props) {
  const { login, children } = props;
  const token = getToken();
  return login ? (
    token ? (
      <Navigate to={"/task"} />
    ) : (
      <>{children}</>
    )
  ) : token ? (
    <>{children}</>
  ) : (
    <Navigate to={"/login"} />
  );
}

export { AuthRouter };
