import { React, useEffect, useState } from "react";
import { Descriptions, Watermark, Image } from "antd";
import styles from "./index.module.css";

const Info = (props) => {
  const [items, setItems] = useState();
  const watermarkContent = ["CIBN监播", localStorage.getItem("username")];

  function getValue(name) {
    if (items) {
      return items[name];
    } else {
      return "";
    }
  }

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  return (
    <div className={styles.infoPanel}>
      <Watermark content={watermarkContent}>
        <Descriptions
          column={2}
          labelStyle={{ width: "22%", textAlign: "center" }}
          contentStyle={{ width: "28%" }}
          size="small"
          title=""
          bordered
        >
          <Descriptions.Item label="申请方">
            {getValue("applicant_org")}
          </Descriptions.Item>
          <Descriptions.Item label="申请人">
            {getValue("applicant")}
          </Descriptions.Item>
          <Descriptions.Item label="直播开始时间">
            {getValue("start_time")}
          </Descriptions.Item>
          <Descriptions.Item label="直播结束时间">
            {getValue("end_time")}
          </Descriptions.Item>
          <Descriptions.Item label="直播间ID">
            {getValue("live_id")}
          </Descriptions.Item>
          <Descriptions.Item label="直播间名称">
            {getValue("live_name")}
          </Descriptions.Item>
          <Descriptions.Item label="主播姓名">
            {getValue("live_uname")}
          </Descriptions.Item>
          <Descriptions.Item label="认证编号">
            {getValue("certification_no")}
          </Descriptions.Item>
          <Descriptions.Item label="参与嘉宾">
            {getValue("guest")}
          </Descriptions.Item>
          <Descriptions.Item label="播出平台">
            {getValue("platform")}
          </Descriptions.Item>
          <Descriptions.Item label="送审状态">
            {getValue("goprocess_status")}
          </Descriptions.Item>
          <Descriptions.Item label="审核状态">
            {getValue("process_status")}
          </Descriptions.Item>
          <Descriptions.Item label="活动类型">
            {getValue("event_type")}
          </Descriptions.Item>
          <Descriptions.Item label="活动主办方">
            {getValue("event_organizer")}
          </Descriptions.Item>
          <Descriptions.Item label="活动合作方">
            {getValue("event_partner")}
          </Descriptions.Item>
          <Descriptions.Item label="来源渠道方">
            {getValue("channel_name")}
          </Descriptions.Item>
          <Descriptions.Item label="自制或转播">
            {getValue("source")}
          </Descriptions.Item>
          <Descriptions.Item label="转播来源">
            {getValue("relay_source")}
          </Descriptions.Item>
          <Descriptions.Item label="编码格式">
            {getValue("encoding_format")}
          </Descriptions.Item>
          <Descriptions.Item label="横/竖屏">
            {getValue("screen_format")}
          </Descriptions.Item>
          <Descriptions.Item label="直播活动分类">
            {getValue("live_event_type")}
          </Descriptions.Item>
          <Descriptions.Item label="直播监播分类">
            {getValue("live_monitoring_type")}
          </Descriptions.Item>
          <Descriptions.Item label="运营负责人">
            {getValue("operate_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("operate_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="监播负责人">
            {getValue("monitoring_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("monitoring_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="项目负责人">
            {getValue("project_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("project_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="业务负责人">
            {getValue("business_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("business_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="技术负责人">
            {getValue("technology_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("technology_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="来源负责人">
            {getValue("channel_manager_name")}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {getValue("channel_manager_mobile")}
          </Descriptions.Item>
          <Descriptions.Item label="转播地址" span={2}>
            {getValue("source_addr")}
          </Descriptions.Item>
          <Descriptions.Item label="实时直播" span={2}>
            {getValue("play_addr")}
          </Descriptions.Item>
          <Descriptions.Item label="备片列表" span={2}>
            {getValue("preplay_addr")}
          </Descriptions.Item>
          <Descriptions.Item label="主播认证照" span={2}>
            {getValue("certif_imgs") &&
              Array.isArray(getValue("certif_imgs")) &&
              getValue("certif_imgs").map((item, index) => {
                return <Image key={index} height={200} src={item} />;
              })}
          </Descriptions.Item>
        </Descriptions>
      </Watermark>
    </div>
  );
};
export default Info;
