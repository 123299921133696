import React, { useEffect, useState, useCallback, useRef } from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import {
  Input,
  Button,
  Card,
  Table,
  Modal,
  Descriptions,
  message,
  Watermark,
  Popconfirm,
  Tooltip,
  Form,
  Image,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { reqPlanList, reqPlanStatus } from "@/api/pagelist/pagelist";
import styles from "./index.module.css";

const Approval = () => {
  const [denyId, setDenyId] = useState();

  // 声明状态
  const [planList, setPlanList] = useState([]);
  const [planInfo, setPlanInfo] = useState({});
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenDeny, setIsModalOpenDeny] = useState(false);
  let [loading, setLoading] = useState(false);
  // 声明
  const [total, setTotal] = useState(1);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const formRef = useRef(null);
  let token = localStorage.getItem("token");
  const watermarkContent = ["CIBN监播", localStorage.getItem("username")];

  // 结构
  const columns = [
    {
      title: "直播间名称",
      dataIndex: "live_name",
      width: "10%",
      sorter: (a, b) => a.live_name.localeCompare(b.live_name),
    },
    {
      title: "主播姓名",
      dataIndex: "live_uname",
      width: "10%",
      sorter: (a, b) => a.live_uname.localeCompare(b.live_uname),
    },
    {
      title: "认证编号",
      dataIndex: "certification_no",
      sorter: (a, b) => a.live_uname.localeCompare(b.certification_no),
      width: "15%",
    },
    {
      title: "直播开始时间",
      dataIndex: "start_time",
      width: "10%",
      locale,
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      defaultSortOrder: "descend",
    },
    {
      title: "直播结束时间",
      dataIndex: "end_time",
      width: "10%",
    },
    {
      title: "参与嘉宾",
      dataIndex: "guest",
    },
    {
      title: "审核状态",
      dataIndex: "process_status",
      width: "10%",
      render: (e) => {
        return (
          <span
            style={{
              color:
                e === "待审核" ? "black" : e === "审核已通过" ? "blue" : "red",
            }}
          >
            {e}
          </span>
        );
      },
    },
    {
      title: "相关操作",
      dataIndex: "plan_id",
      width: "170px",
      fixed: "right",
      render: (plan_id, record) => (
        <div className={styles.box}>
          <Tooltip placement="bottom" title="查看详情">
            <Button
              onClick={() => showDetail(record)}
              type="primary"
              shape="round"
              icon={<FileSearchOutlined />}
            />
          </Tooltip>
          <div
            style={{
              display: record.process_status === "待审核" ? "flex" : "none",
            }}
          >
            <Tooltip placement="bottom" title="审核通过">
              <Button
                style={{ background: "#42B71F" }}
                type="primary"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => updatePlanStatus(plan_id, 2, "")}
              />
            </Tooltip>
            <Popconfirm
              placement="topLeft"
              title={"确认拒绝？"}
              onConfirm={() => {
                setIsModalOpenDeny(true);
                setDenyId(plan_id);
              }}
            >
              <Tooltip placement="bottom" title="拒绝通过">
                <Button
                  style={{ marginLeft: "5px" }}
                  danger
                  type="primary"
                  shape="circle"
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  // 查看详情
  const showDetail = (record) => {
    setPlanInfo(record);
    setIsModalOpenDetail(true);
  };
  const hideDetail = () => {
    setIsModalOpenDetail(false);
  };

  // 调用接口
  const updatePlanStatus = async (plan_id, status, desc) => {
    let res = await reqPlanStatus(
      "plan_id=" +
        plan_id +
        "&process_status=" +
        status +
        "&process_desc=" +
        desc +
        "&token=" +
        token
    );
    if (res) {
      message.success("成功");
      const list = planList.map((item) => {
        if (item.plan_id === plan_id) {
          item.process_status = status === 2 ? "审核已通过" : "审核未通过";
        }
        return item;
      });
      setPlanList(list);
    }
  };
  // check form valid
  const validateReason = (plan_id) => {
    formRef.current
      .validateFields()
      .then((values) => {
        updatePlanStatus(plan_id, 3, values.reason);
        setIsModalOpenDeny(false);
      })
      .catch((info) => {
        let error = info.errorFields;
        error.forEach((item) => {
          console.log("error", item.errors);
        });
      });
  };

  // 排序
  const [sortedInfo, setSortedInfo] = useState({});
  const sortList = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  // 声明列表接口函数
  const getPlanList = useCallback(async () => {
    setLoading(true);
    let res = await reqPlanList("token=" + token + "&process_status=1");
    if (res) {
      setPlanList(res.data.list);
      setTotal(res.data.list_num);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getPlanList();
  }, [getPlanList]);

  return (
    <>
      <Watermark content={watermarkContent}>
        <Card>
          <Table
            columns={columns}
            loading={loading}
            scroll={{
              x: 1300,
            }}
            dataSource={planList}
            rowKey="plan_id"
            onChange={sortList}
            sortedInfo={sortedInfo}
            bordered={true}
            pagination={{
              total,
              current,
              pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: [20, 50, 100],
              showTotal: (total) => {
                return `总计 ${total}条`;
              },
              onChange: (page, pageSize) => {
                setPageSize(pageSize);
                setCurrent(page);
              },
            }}
            onRow={(record) => {
              return {
                onMouseEnter: () => {},
              };
            }}
          />
        </Card>
      </Watermark>
      <Modal
        title="直播计划表"
        open={isModalOpenDetail}
        onOk={hideDetail}
        onCancel={hideDetail}
        okText="确认"
        cancelText="取消"
        width={800}
      >
        <Watermark content={watermarkContent}>
          <Descriptions
            column={2}
            labelStyle={{ width: "22%", textAlign: "center" }}
            contentStyle={{ width: "28%" }}
            size="small"
            title=""
            bordered
          >
            <Descriptions.Item label="申请方">
              {planInfo.applicant_org}
            </Descriptions.Item>
            <Descriptions.Item label="申请人">
              {planInfo.applicant}
            </Descriptions.Item>
            <Descriptions.Item label="直播开始时间">
              {planInfo.start_time}
            </Descriptions.Item>
            <Descriptions.Item label="直播结束时间">
              {planInfo.end_time}
            </Descriptions.Item>
            <Descriptions.Item label="直播间ID">
              {planInfo.live_id}
            </Descriptions.Item>
            <Descriptions.Item label="直播间名称">
              {planInfo.live_name}
            </Descriptions.Item>
            <Descriptions.Item label="主播姓名">
              {planInfo.live_uname}
            </Descriptions.Item>
            <Descriptions.Item label="认证编号">
              {planInfo.certification_no}
            </Descriptions.Item>
            <Descriptions.Item label="参与嘉宾">
              {planInfo.guest}
            </Descriptions.Item>
            <Descriptions.Item label="播出平台">
              {planInfo.platform}
            </Descriptions.Item>
            <Descriptions.Item label="送审状态">
              {planInfo.goprocess_status}
            </Descriptions.Item>
            <Descriptions.Item label="审核状态">
              {planInfo.process_status}
            </Descriptions.Item>
            <Descriptions.Item label="活动类型">
              {planInfo.event_type}
            </Descriptions.Item>
            <Descriptions.Item label="活动主办方">
              {planInfo.event_organizer}
            </Descriptions.Item>
            <Descriptions.Item label="活动合作方">
              {planInfo.event_partner}
            </Descriptions.Item>
            <Descriptions.Item label="来源渠道方">
              {planInfo.channel_name}
            </Descriptions.Item>
            <Descriptions.Item label="自制或转播">
              {planInfo.source}
            </Descriptions.Item>
            <Descriptions.Item label="转播来源">
              {planInfo.relay_source}
            </Descriptions.Item>
            <Descriptions.Item label="编码格式">
              {planInfo.encoding_format}
            </Descriptions.Item>
            <Descriptions.Item label="横/竖屏">
              {planInfo.screen_format}
            </Descriptions.Item>
            <Descriptions.Item label="直播活动分类">
              {planInfo.live_event_type}
            </Descriptions.Item>
            <Descriptions.Item label="直播监播分类">
              {planInfo.live_monitoring_type}
            </Descriptions.Item>
            <Descriptions.Item label="运营负责人">
              {planInfo.operate_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.operate_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="监播负责人">
              {planInfo.monitoring_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.monitoring_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="项目负责人">
              {planInfo.project_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.project_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="业务负责人">
              {planInfo.business_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.business_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="技术负责人">
              {planInfo.technology_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.technology_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="来源负责人">
              {planInfo.channel_manager_name}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {planInfo.channel_manager_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="转播地址" span={2}>
              {planInfo.source_addr}
            </Descriptions.Item>
            <Descriptions.Item label="实时直播" span={2}>
              {planInfo.play_addr}
            </Descriptions.Item>
            <Descriptions.Item label="备片列表" span={2}>
              {planInfo.preplay_addr}
            </Descriptions.Item>
            <Descriptions.Item label="主播认证照" span={2}>
              {planInfo.certif_imgs &&
                Array.isArray(planInfo.certif_imgs) &&
                planInfo.certif_imgs.map((item, index) => {
                  return <Image key={index} height={200} src={item} />;
                })}
            </Descriptions.Item>
          </Descriptions>
        </Watermark>
      </Modal>
      <Modal
        title="请填写未通过原因"
        open={isModalOpenDeny}
        onOk={() => {
          validateReason(denyId);
        }}
        onCancel={() => setIsModalOpenDeny(false)}
        destroyOnClose
      >
        <Form ref={formRef}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "未通过原因必须输入",
              },
            ]}
          >
            <Input.TextArea
              allowClear
              placeholder="请输入未通过原因"
              style={{
                height: 120,
                resize: "none",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Approval;
