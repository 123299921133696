import Mpegts from 'mpegts.js';

const setPlayer = (url, videoElementId, hasAudio) => {
  let videoElement = document.getElementById(videoElementId);
  let newurl = url.replace(".m3u8", ".flv");
  let player = Mpegts.createPlayer({
    type: 'flv',
    isLive: true,
    hasAudio: hasAudio,
    url: newurl
  });
  console.log("Create player: ", newurl, videoElement);
  player.attachMediaElement(videoElement);
  player.load();
  player.play();
  return player;
};

const unloadPlayer = (playerRef) => {
  if (playerRef) {
    console.log("Unload player: ", playerRef);
    playerRef.unload();
    playerRef.detachMediaElement();
  }
};

export { setPlayer, unloadPlayer };
